.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.architecture-menu {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.architecture-menu button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.architecture-viewer {
  text-align: center;
}

.architecture-viewer img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-top: 20px;
}

.architecture-viewer a {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.architecture-viewer a:hover {
  text-decoration: underline;
}
